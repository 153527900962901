import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";

export const WhatsAppLink = () => {
  return (
    <StyledWhatsAppLink
      href="https://api.whatsapp.com/send?phone=34694432257"
      passHref
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image src="/svg/whatsapp-icon.svg" alt="WhatsApp Icon" width={32} height={32} />
    </StyledWhatsAppLink>
  );
};

const StyledWhatsAppLink = styled(Link)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 12px;
  background-color: #25d366;
  color: white;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  z-index: 1000;

  &:hover {
    background-color: #1ebc5e;
    transform: scale(1.1);
  }

  &:active {
    background-color: #199d50;
    transform: scale(0.95);
  }
`;
